
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AppTable from '@/components/global/app-table/AppTable.vue';
import Project from '@/models/Project';
import {
    allColumnData,
    createColumnData,
    leadsColumnData,
    offersColumnData,
    ordersColumnData,
} from '@/helpers/TableHelper';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { FilterParameters } from '@/interfaces/components/FilterParameters';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import {
    checkIfLocalStorageParametersExist,
    constructUrlParams,
    handleAndCleanUpSearchingForEntity,
    setCorrectPaginationAndSorters,
} from '@/helpers/SearchHelper';
import { ColumnSortedInfo } from '@/interfaces/ColumnSortedInfo';
import { UserRepository } from '@/repositories/UserRepository';
import { ElementFilterType } from '@/enums/global/ElementFilterType';
import HomeUpperSection from '@/components/views/home/HomeUpperSection.vue';
import { Getter } from 'vuex-class';
import { LocalStorageService } from '@/services/LocalStorageService';
import User from '@/models/User';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import TableSearchBar from '@/components/global/TableSearchBar.vue';
import TableFilters from '@/components/views/home/TableFilters.vue';

@Component({
    name: 'ClientProjectsTableWrapper',
    components: {
        AppTable,
        HomeUpperSection,
        TableSearchBar,
        TableFilters,
    },
})
export default class ClientProjectsTableWrapper extends Vue {
    @Getter('tableData/projects')
    private projects!: boolean;
    @Prop({ required: true }) private clientId!: null | string;
    @Prop({ required: false, default: false }) private isReadOnly!: boolean;

    private sortedInfo: ColumnSortedInfo | null = null;
    private sortField: string | null = null;
    private sortOrder: string | undefined | null = null;
    private isLoading = false;
    private entityType: string = ProjectTypes.All as string;
    private search = { isActive: true };
    private areFiltersVisible = false;
    private filter = [
        {
            defaultValue: '',
            filter: 'clientId',
            isMandatory: true,
            label: 'Stranka',
            type: ElementFilterType.Dropdown,
            value: this.clientId,
        },
    ];

    private get user() {
        return UserRepository.getCurrentUser();
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get isCurrentUserGroupRoltek() {
        return this.currentUserGroup && this.currentUserGroup.name === 'Roltek';
    }

    private get projectData() {
        return this.projects;
    }

    private onUpdateEntityType(entityType: string) {
        this.entityType = entityType;
    }

    private getColumnData() {
        let columnOptions = leadsColumnData;

        if (this.entityType === ProjectTypes.Order) {
            columnOptions = ordersColumnData;
        }

        if (this.entityType === ProjectTypes.Offer) {
            columnOptions = offersColumnData;
        }

        if (this.entityType === ProjectTypes.Request) {
            columnOptions = offersColumnData;
        }

        if (this.entityType === ProjectTypes.All) {
            columnOptions = allColumnData;
        }

        return createColumnData<Project>(
            columnOptions,
            this.sortedInfo,
            this.entityType,
            this.isCurrentUserGroupRoltek,
            this.onRowClick
        );
    }

    private updateSortedInfo(sorter: ColumnSortedInfo) {
        this.sortedInfo = sorter;
        this.sortField = sorter.columnKey;
        this.sortOrder = sorter.order;
    }

    private async onUpdateSorter(params: FilterParameters) {
        this.startLoading();

        this.updateSortedInfo({
            columnKey: params.sortColumnKey,
            order: params.sortOrder,
        });

        if (params.sortField != null) {
            this.sortField = params.sortField;
            this.sortOrder = params.sortOrder;
        }

        EventBus.$emit(EventBusEvents.runEntitySearch, { ...params, shouldOverrideSortParameters: false });
    }

    private async onRowClick() {
        this.$store.commit('tableData/clearProjectNavigationSettings');
        const updatedEntityType = `clientsProjects-${this.entityType}`;

        if (checkIfLocalStorageParametersExist(this.user, updatedEntityType)) {
            const localStorageParameters = JSON.parse(
                LocalStorageService.get(
                    // @ts-ignore
                    `${updatedEntityType}-${this.user.id}`
                ) as string
            );

            const query = constructUrlParams(localStorageParameters);
            const payload = await Project.fetchFilteredProjectsForNavigation(query);

            this.$store.dispatch('tableData/updateProjectNavigationSettings', {
                ...payload,
                page: localStorageParameters.page,
                userId: (this.user as User).id,
                entityType: updatedEntityType,
            });

            return Promise.resolve();
        }

        return Promise.resolve();
    }

    private async onMadeByUserSelect(assignedUserId: string, entityId: string) {
        this.startLoading();
        try {
            await Project.updateExisting({
                projectId: entityId,
                assignedUserId,
            });
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.stopLoading();
            return;
        }
        this.$notification.success({
            message: this.$t('Komercijalist uspješno dodijeljen!') as string,
            description: this.$t('Projekt je maknut iz ove liste') as string,
        });

        this.stopLoading();
    }

    private startLoading() {
        this.isLoading = true;
    }

    private stopLoading() {
        this.isLoading = false;
    }

    private onSearchFinished() {
        this.stopLoading();
    }

    private fireToggleFilters() {
        EventBus.$emit(EventBusEvents.toggleFilters);
    }

    private toggleFilters() {
        this.areFiltersVisible = !this.areFiltersVisible;
    }

    private created() {
        EventBus.$on(EventBusEvents.updateSortedInfo, this.updateSortedInfo);
        EventBus.$on(EventBusEvents.searchFinished, this.onSearchFinished);
        EventBus.$on(EventBusEvents.toggleFilters, this.toggleFilters);
        EventBus.$on(EventBusEvents.updateProject, this.updateProject);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.updateSortedInfo, this.updateSortedInfo);
        EventBus.$off(EventBusEvents.searchFinished, this.onSearchFinished);
        EventBus.$off(EventBusEvents.toggleFilters, this.toggleFilters);
        EventBus.$off(EventBusEvents.updateProject, this.updateProject);
    }

    private async onSearch({ results, page, sortField, sortOrder }: any = {}) {
        if (this.clientId == null || this.user == null) {
            Project.deleteAll();
            return;
        }
        const newSearchParameters = {
            isSearchActive: true,
            searchQuery: undefined,
            entityType: this.entityType,
            availableFilters: this.filter,
            sortField,
            sortOrder,
            results,
            page,
        };

        setCorrectPaginationAndSorters(newSearchParameters, null, 'sortDate', 'descend', false);

        try {
            await handleAndCleanUpSearchingForEntity(newSearchParameters, this.user.id, true, true);
        } catch (e) {
            EventBus.$emit(EventBusEvents.searchFinished);

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            return;
        }
    }

    private async updateProject(args: any) {
        this.startLoading();
        try {
            if (args?.projectId && args?.labelId) {
                await Project.updateProjectLabel(args.projectId, args.labelId);
            }
            this.getColumnData();
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
        } finally {
            this.stopLoading();
        }
    }

    @Watch('projectData')
    private onProjectDataChange() {
        if (this.sortOrder == null) {
            this.sortField = 'sortDate';
            this.sortOrder = 'descend';
        }
    }

    @Watch('entityType', { immediate: true })
    private async onEntityTypeChange() {
        this.sortField = null;
        this.sortOrder = null;

        this.startLoading();

        try {
            this.onSearch();
        } catch (e) {
            this.onSearchFinished();
            return;
        }
    }
}
